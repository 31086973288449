.Fahrzeug-Innen {
    font-family: arial, sans-serif;
    font-size: large;
    width: 100%;
    height: 100%;;
    display: grid;
    grid-template: 40px 45px 66px 15px 23px 30px 30px / 2fr 18px 70px 70px 70px 3fr;
}

.Fahrzeug-Container {
    border: dashed black 0px;
}

.Fahrzeug-Grundzeichen {
    grid-area: 2 / 3 / span 3 / span 3;
    align-self: center;
    display: grid;
    grid-template: 1fr/ 1fr;

}

.Fahrzeug-Grundzeichen img {
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    max-height: 160px;
    object-fit: scale-down;

}

.Fahrzeug-Symbol {
    grid-area: 3 / 3 / span 1 / span 3;
    align-self: center;
    display: grid;
    grid-template: 1fr / 1fr;
}

.Fahrzeug-Symbol img {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-height: 140px;
}

.Fahrzeug-Symbol p {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 200px;
    max-height: 60px;
    font-weight: bold;
    text-align: center;
    font-size: 35px;
}

.Fahrzeug-Groessenordnung {
    grid-area: 1 / 4 / span 1 / span 1;
    align-self: end;
}

.Fahrzeug-Groessenordnung img {
    max-height: 1.4rem;
}

.Fahrzeug-Vstkverm {
    grid-area: 1 / 5 / span 1 / span 1;
    align-self: center;
}

.Fahrzeug-FreitextAussen {
    grid-area: 1 / 6 / span 1 / span 1;
    text-align: left;
    align-self: center;
    padding-left: 5px;
}

.Fahrzeug-Fuehrung-On {
    grid-area: 2 / 3 / span 1 / span 3;
}

.Fahrzeug-Fuehrung-Off {
    grid-area: 2 / 3 / span 1 / span 3;
    display:none
}

.Fahrzeug-Ortsfest-On {
    grid-area: 1/ 3 / span 1 / span 3;
}

.Fahrzeug-Ortsfest-Off {
    grid-area: 1/ 3 / span 1 / span 3;
    display: none;
}


.Fahrzeug-FreitextInnenOben {
    grid-area: 3 / 3 / span 1 / span 1;
    font-weight: bold;
    text-align: left;
    align-self: flex-start;
    padding-left: 5px;
}


.Fahrzeug-FreitextInnenUnten {
    grid-area: 4 / 5 / span 1 / span 1;
    font-weight: bold;
    text-align: right;
    align-self: flex-end;
    padding-right: 5px;
}

.Fahrzeug-FreitextInnenUnten-Logistik {
    grid-area: 3 / 5 / span 1 / span 1;
    font-weight: bold;
    text-align: right;
    align-self: flex-end;
    padding-right: 5px;
}
 

.Fahrzeug-Logistik-On {
    grid-area: 4 / 3 / span 1 / span 3;
    align-self: flex-start;
}

.Fahrzeug-Logistik-Off {
    grid-area: 4 / 3 / span 1 / span 3;
    display: none;
}

.Fahrzeug-Rufname {
    grid-area: 3 / 6 / span 2 / span 1;
    text-align: left;
    font-size: larger;
    align-self: flex-end;
    padding-left: 5px;
    font-weight: bold;
    text-overflow: clip;
}

.Fahrzeug-Von {
    grid-area: 6 / 3 / span 1 / span 1;
    text-align: right;
    padding-left: 10px;
}

.Fahrzeug-Nach {
    grid-area: 6 / 5 / span 1 / span 1;
    text-align: left;
    padding-right: 5px;
}

.Fahrzeug-Bewegungsart {
    grid-area: 6 / 4 / span 1 / span 1;
}

.Fahrzeug-Von-Staerke {
    grid-area: 7 / 3 / span 1 / span 1;
    text-align: right;
    padding-left: 10px;
}

.Fahrzeug-Nach-Staerke {
    grid-area: 7 / 5 / span 1 / span 1;
    text-align: left;
    padding-right: 5px;
}

.Fahrzeug-Bewegungsart-Staerke {
    grid-area: 7 / 4 / span 1 / span 1;
}


.Fahrzeug-Staerke {
    grid-area: 6 / 3 / span 1 / span 3;
    align-self: flex-start;
    text-align: center;
}


.Fahrzeug-Dtg {
    grid-area: 2 / 1 / span 1 / span 2;
    text-align: right;
    align-self: center;
    padding-right: 5px;
}

.Fahrzeug-Beweglichkeit {
    grid-area: 2 / 2 / span 4 / span 4;
    text-align: right;
    align-self: flex-end;
}

.Fahrzeug-Download {
    position: relative;
    top: -395px;
    left: 575px;
}
