.Person-Innen {
    font-family: arial, sans-serif;
    font-size: large;
    width: 100%;
    height: 100%;;
    display: grid;
    grid-template: 40px 30px 100px 30px 30px 30px / 1fr 160px 1fr;
}

.Person-Container {
    border: dashed black 0px;
}

.Person-Grundzeichen {
    grid-area: 2 / 2 / span 3 / span 1;
    align-self: center;
    display: grid;
    grid-template: 1fr/ 1fr;

}

.Person-Grundzeichen img {
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    max-height: 160px;
    object-fit: scale-down;

}

.Person-Symbol {
    grid-area: 3 / 2 / span 1 / span 1;
    align-self: center;
    display: grid;
    grid-template: 1fr / 1fr;
}

.Person-Symbol img {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 155px;
    max-height: 160px;
}

.Person-Symbol p {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 120px;
    max-height: 90px;
    font-weight: bold;
    text-align: center;
    font-size: 40px;
}


.Person-Groessenordnung {
    grid-area: 1 / 2 / span 1 / span 1;
    align-self: end;
}

.Person-Groessenordnung img {
    max-height: 1.4rem;
}

.Person-Vstkverm {
    grid-area: 1 / 3 / span 1 / span 1;
    text-align: left;
    align-self: end;
}

.Person-FreitextAussen {
    grid-area: 2 / 3 / span 1 / span 1;
    text-align: left;
    align-self: center;

}

.Person-Fuehrung-On {
    grid-area: 2 / 2 / span 1 / span 1;
}

.Person-Fuehrung-Off {
    grid-area: 2 / 2 / span 1 / span 1;
    display:none
}

.Person-Ortsfest-On {
    grid-area: 1/ 2 / span 1 / span 1;
}

.Person-Ortsfest-Off {
    grid-area: 1/ 2 / span 1 / span 1;
    display: none;
}

.Person-FreitextInnenOben {
    grid-area: 3 / 2 / span 1 / span 1;
    font-weight: bold;
    text-align: center;
    align-self: flex-start;

}


.Person-FreitextInnenUnten {
    grid-area: 3 / 2 / span 1 / span 1;
    font-weight: bold;
    text-align: center;
    align-self: flex-end;
}
 

.Person-Logistik-On {
    grid-area: 4 / 2 / span 1 / span 1;
    transform: rotate(180deg);
}

.Person-Logistik-Off {
    grid-area: 4 / 2 / span 1 / span 3;
    display: none;
}

.Person-Rufname {
    grid-area: 3 / 3 / span 2 / span 1;
    text-align: left;
    font-size: larger;
    align-self: flex-end;
    padding-left: 5px;
    font-weight: bold;
    text-overflow: clip;
}

.Person-Von {
    grid-area: 5 / 1 / span 1 / span 1;
    text-align: right;
    align-self: center;
}

.Person-Nach {
    grid-area: 5 / 3 / span 1 / span 1;
    text-align: left;
    align-self: center;
}

.Person-Bewegungsart {
    grid-area: 5 / 2 / span 1 / span 1;
    object-fit: scale-down;
}

.Person-Staerke {
    grid-area: 5 / 2 / span 1 / span 1;
    padding-top: 5px;
    display: none
}


.Person-Dtg {
    grid-area: 3 / 1 / span 1 / span 1;
    text-align: right;
    align-self: center;
}

.Person-Download {
    position: relative;
    top: -395px;
    left: 575px;
}
