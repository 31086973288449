.App {
  position: relative;
  margin: auto;
  height: 100%;
  max-width: 1000px;
  max-height: 550px;
  padding: 10px;
  text-align: center;
  display: grid;
  background-color: #282c34;
  grid-template: 40px 1fr 1fr 90px 90px 90px 1fr / 1fr 180px 160px 140px 1fr;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-areas:  "head head head head head"
                        "grundz fuelog inner linie buttons"
                        'symb fuelog groeo vstkverm freiaussen'
                        'symb zeichen zeichen zeichen innenoben'
                        'symb zeichen zeichen zeichen innenunten'
                        'symb zeichen zeichen zeichen ruf'
                        'dtg bewegl staerke bewega bewega'
}

.Container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  background-color: rgba(255,255,255,0.7);
  color: black;
  border-radius: 5px;
}

.Fuellfarbe {
  grid-area: inner;
}

.Linienfarbe {
  grid-area: linie;
}

.Buttons{
  grid-area: buttons;
}

 .ZeichenEinheit,
 .ZeichenStelle,
 .ZeichenPerson,
 .ZeichenFahrzeug,
 .ZeichenBoot,
 .ZeichenMassnahme,
 .ZeichenEreignis,
 .ZeichenGefahr {
  grid-area: zeichen;
  justify-items: center;
 }

 .Symbol {
  grid-area: symb;
  align-items: center;
  min-width: 220px;
 }

 .Symbol-Header {
  padding: 0;
  height:1.5rem;
  display: inline-block;
 } 

 .Symbol .Table {
  height: 95%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  background-color: rgba(255,255,255,0.8);
 }

 .Table td {
  border-bottom: solid rgba(0,0,0,0.5) 1px;
 }

.Grundzeichen {
 grid-area: grundz;
}

.FuehrungLogistik {
  grid-area: fuelog;
}

.Groessenordnung {
  grid-area: groeo;
}

.VerstaerktVermindert {
  grid-area: vstkverm;
}

.FreitextAussenObenRechts {
  grid-area: freiaussen;
}

.FreitextInnenObenLinks {
  grid-area: innenoben;
}

.FreitextInnenUntenRechts {
  grid-area: innenunten;
}

.Rufname {
  grid-area: ruf;
 }

 .Bewegungsart {
  grid-area: bewega;
  display: grid;
  grid-template-columns: 1fr 1fr;
 }

 .Bewegungsart input {
  max-width: 50%;
  margin-left: 10px;
 }

 .Beweglichkeit {
  grid-area: bewegl;
 }

.Staerke {
  grid-area: staerke;
}

.Staerke input {
  max-width: 80%;
;
}

.DTG {
  grid-area: dtg;
}


.App-header {
  display: grid;
  background-color: #282c34;
  font-size: 20px;
  color: white;
  grid-area: head;
  align-items: center;
}

.Container img {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}


p {
  margin: 0;
}


