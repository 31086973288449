.Einheit-Innen {
    font-family: arial, sans-serif;
    font-size: large;
    width: 480px;
    height: 260px;
    display: grid;
    grid-template: 40px 30px 30px 40px 30px 30px 30px 30px / 2fr 1fr 2fr 1fr 2fr;
}

.Einheit-Container {
    border: dashed black 0px;
}

.Einheit-Grundzeichen {
    grid-area: 2 / 2 / span 5 / span 3;
    align-self: center;
    display: grid;
    grid-template: 100% / 100%;

}

.Einheit-Grundzeichen img {
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    object-fit: scale-down;

}

.Einheit-Symbol {
    grid-area: 2 / 2 / span 5 / span 3;
    align-self: center;
    display: grid;
    grid-template: 1fr / 1fr;
}

.Einheit-Symbol img {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-height: 160px;
}

.Einheit-Symbol p {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 220px;
    max-height: 90px;
    font-weight: bold;
    text-align: center;
    font-size: 50px;
}

.Einheit-Groessenordnung {
    grid-area: 1 / 3 / span 1 / span 1;
    align-self: end;
}

.Einheit-Groessenordnung img {
    max-height: 20px;
}

.Einheit-Vstkverm {
    grid-area: 1 / 4 / span 1 / span 1;
    align-self: center;
}

.Einheit-FreitextAussen {
    grid-area: 1 / 5 / span 1 / span 1;
    text-align: left;
    align-self: center;
    padding-left: 5px;
}

.Einheit-Fuehrung-On {
    grid-area: 2 / 2 / span 1 / span 3;
    background-color: black;
}



.Einheit-FreitextInnenOben {
    grid-area: 2 / 2 / span 1 / span 1;
    font-weight: bold;
    text-align: left;
    align-self: flex-start;
    padding: 5px 0 0 5px;
}

.Einheit-FreitextInnenOben-Fuehrung {
    grid-area: 3 / 2 / span 1 / span 1;
    font-weight: bold;
    text-align: left;
    align-self: flex-start;
    padding: 5px 0 0 5px;
}

.Einheit-FreitextInnenUnten {
    grid-area: 6 / 4 / span 1 / span 1;
    font-weight: bold;
    text-align: right;
    align-self: flex-end;
    padding: 0 10px 5px 0;
}

.Einheit-FreitextInnenUnten-Logistik {
    grid-area: 5 / 4 / span 1 / span 1;
    font-weight: bold;
    text-align: right;
    align-self: flex-end;
    padding: 0 10px 5px 0;
}
 

.Einheit-Logistik-On {
    grid-area: 6 / 2 / span 1 / span 3;
    background-color: black;
}

.Einheit-Fuehrungsstelle-On {
    grid-area: 7 / 2 / span 2 / span 2;
    border-left: solid black 3px;
}

.Einheit-Ortsfest-On {
    grid-area: 1/ 2 / span 1 / span 3;
}

.Einheit-Ortsfest-Off {
    grid-area: 1/ 2 / span 1 / span 3;
    display: none;
}

.Einheit-Rufname {
    grid-area: 4 / 5 / span 3 / span 1;
    text-align: left;
    font-size: larger;
    align-self: flex-end;
    padding-left: 5px;
    font-weight: bold;
    text-overflow: clip;
}

.Einheit-Von {
    grid-area: 8 / 2 / span 1 / span 1;
}

.Einheit-Nach {
    grid-area: 8 / 4 / span 1 / span 1;
}

.Einheit-Bewegungsart {
    grid-area: 8 / 3 / span 1 / span 1;
}

.Einheit-Staerke {
    grid-area: 7 / 3 / span 1 / span 1;
    padding-top: 5px;
}


.Einheit-Dtg {
    grid-area: 2 / 1 / span 1 / span 1;
}

.Einheit-Download {
    position: relative;
    top: -395px;
    left: 575px;
}
