.Stelle-Innen {
    font-family: arial, sans-serif;
    font-size: large;
    width: 100%;
    height: 100%;;
    display: grid;
    grid-template: 40px 30px 100px 30px 30px 30px / 1fr 160px 1fr;
}

.Stelle-Container {
    border: dashed black 0px;
}

.Stelle-Grundzeichen {
    grid-area: 2 / 2 / span 3 / span 1;
    align-self: center;
    display: grid;
    grid-template: 1fr/ 1fr;

}

.Stelle-Grundzeichen img {
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    max-height: 160px;
    object-fit: scale-down;

}

.Stelle-Symbol {
    grid-area: 3 / 2 / span 1 / span 1;
    align-self: center;
    display: grid;
    grid-template: 1fr / 1fr;
}

.Stelle-Symbol img {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 160px;
    max-height: 160px;
}

.Stelle-Symbol p {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 130px;
    max-height: 90px;
    font-weight: bold;
    text-align: center;
    font-size: 45px;
}

.Stelle-Groessenordnung {
    grid-area: 1 / 2 / span 1 / span 1;
    align-self: end;
}

.Stelle-Groessenordnung img {
    max-height: 1.4rem;
}

.Stelle-Vstkverm {
    grid-area: 1 / 3 / span 1 / span 1;
    align-self: center;
}

.Stelle-FreitextAussen {
    grid-area: 2 / 3 / span 1 / span 1;
    text-align: left;
    align-self: center;
    padding-left: 5px;
}

.Stelle-Fuehrung-On {
    grid-area: 2 / 2 / span 1 / span 1;
}

.Stelle-Fuehrung-Off {
    grid-area: 2 / 2 / span 1 / span 1;
    display:none
}

.Stelle-Ortsfest-On {
    grid-area: 1/ 2 / span 1 / span 1;
}

.Stelle-Ortsfest-Off {
    grid-area: 1/ 2 / span 1 / span 1;
    display: none;
}

.Stelle-FreitextInnenOben {
    grid-area: 2 / 1 / span 1 / span 1;
    font-weight: bold;
    text-align: right;
    align-self: flex-end;
    padding: 5px 0 0 5px;
}


.Stelle-FreitextInnenUnten {
    grid-area: 4 / 2 / span 1 / span 1;
    font-weight: bold;
    text-align: right;
    align-self: flex-end;
}
 

.Stelle-Logistik-On {
    grid-area: 4 / 2 / span 1 / span 1;
    transform: rotate(180deg);
}

.Stelle-Logistik-Off {
    grid-area: 4 / 2 / span 1 / span 3;
    display: none;
}

.Stelle-Rufname {
    grid-area: 3 / 3 / span 2 / span 1;
    text-align: left;
    font-size: larger;
    align-self: flex-end;
    padding-left: 5px;
    font-weight: bold;
    text-overflow: clip;
}

.Stelle-Von {
    grid-area: 6 / 1 / span 1 / span 1;
    text-align: right;
}

.Stelle-Nach {
    grid-area: 6 / 3 / span 1 / span 1;
    text-align: left;
}

.Stelle-Bewegungsart {
    grid-area: 6 / 2 / span 1 / span 1;
    object-fit: scale-down;
}

.Stelle-Staerke {
    grid-area: 5 / 2 / span 1 / span 1;
    padding-top: 5px;
}


.Stelle-Dtg {
    grid-area: 2 / 1 / span 1 / span 1;
    text-align: right;
}

.Stelle-Download {
    position: relative;
    top: -395px;
    left: 575px;
}
