.Massnahme-Innen {
    font-family: arial, sans-serif;
    font-size: large;
    width: 100%;
    height: 100%;;
    display: grid;
    grid-template: 40px 30px 100px 30px 40px / 1fr 90px 30px 90px 1fr;
}

.Massnahme-Container {
    border: dashed black 0px;
}

.Massnahme-Grundzeichen {
    grid-area: 2 / 2 / span 3 / span 3;
    align-self: center;
    display: grid;
    grid-template: 1fr/ 1fr;

}

.Massnahme-Grundzeichen img {
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    max-height: 160px;
    object-fit: scale-down;

}

.Massnahme-Symbol {
    grid-area: 2 / 2 / span 3 / span 3;
    align-self: flex-end;
    display: grid;
    grid-template: 1fr / 1fr;
}

.Massnahme-Symbol img {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 130px;
    max-height: 120px;
}

.Massnahme-Symbol p {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 130px;
    max-height: 90px;
    font-weight: bold;
    text-align: center;
    font-size: 35px;
}

.Massnahme-Groessenordnung {
    grid-area: 1 / 2 / span 1 / span 1;
    align-self: end;
    display: none;
}

.Massnahme-Groessenordnung img {
    max-height: 1.4rem;
}

.Massnahme-Vstkverm {
    grid-area: 1 / 3 / span 1 / span 1;
    align-self: center;
    display: none;
}

.Massnahme-FreitextAussen {
    grid-area: 2 / 4 / span 1 / span 2;
    text-align: left;
    align-self: flex-end;
    padding-left: 5px;
}

.Massnahme-Fuehrung-On {
    grid-area: 2 / 3 / span 1 / span 1;
}

.Massnahme-Fuehrung-Off {
    grid-area: 2 / 3 / span 1 / span 1;
    display:none
}



.Massnahme-FreitextInnenOben {
    grid-area: 3 / 3 / span 1 / span 1;
    font-weight: bold;
    text-align: center;
    align-self: flex-start;
}


.Massnahme-FreitextInnenUnten {
    grid-area: 3 / 4 / span 1 / span 1;
    font-weight: bold;
    text-align: left;
    align-self: flex-end;
    padding-left: 10px;
}
 

.Massnahme-Logistik-On {
    grid-area: 4 / 2 / span 1 / span 3;
    padding: 2px;
}

.Massnahme-Logistik-Off {
    grid-area: 4 / 2 / span 1 / span 3;
    display: none;
}

.Massnahme-Rufname {
    grid-area: 3 / 5 / span 2 / span 1;
    text-align: left;
    font-size: larger;
    align-self: flex-end;
    padding-left: 5px;
    font-weight: bold;
    text-overflow: clip;
}

.Massnahme-Von {
    grid-area: 5 / 2 / span 1 / span 1;
    text-align: left;
    padding-left: 10px;
}

.Massnahme-Nach {
    grid-area: 5 / 4 / span 1 / span 1;
    text-align: right;
    padding-right: 10px;
}

.Massnahme-Bewegungsart {
    grid-area: 4 / 2 / span 1 / span 3;
    object-fit: scale-down;
    padding: 0 10px;
}

.Massnahme-Staerke {
    grid-area: 5 / 2 / span 1 / span 3;
    padding-top: 5px;
    align-self: flex-start;
    text-align: center;
}


.Massnahme-Dtg {
    grid-area: 2 / 1 / span 1 / span 2;
    text-align: right;
    align-self: flex-end;
}

.Massnahme-Download {
    position: relative;
    top: -395px;
    left: 575px;
}
