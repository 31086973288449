.Boot-Innen {
    font-family: arial, sans-serif;
    font-size: large;
    width: 480px;
    height: 260px;
    display: grid;
    grid-template: 30px 30px 15px 55px 15px 30px 30px / 2fr 30px 100px 30px 3fr;
}

.Boot-Container {
    border: dashed black 0px;
}

.Boot-Grundzeichen {
    grid-area: 3 / 2 / span 3 / span 3;
    align-self: center;
    display: grid;
    grid-template: 100% / 100%;

}

.Boot-Grundzeichen img {
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    object-fit: scale-down;

}

.Boot-Symbol {
    grid-area: 3 / 2 / span 3 / span 3;
    align-self: center;
    display: grid;
    grid-template: 1fr / 1fr;
}

.Boot-Symbol img {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-height: 90px;
}

.Boot-Symbol p {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 220px;
    max-height: 90px;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
}

.Boot-Groessenordnung {
    grid-area: 1 / 3 / span 1 / span 1;
    align-self: end;
}

.Boot-Groessenordnung img {
    max-height: 20px;
}

.Boot-Vstkverm {
    grid-area: 1 / 4 / span 1 / span 1;
    align-self: center;
}

.Boot-FreitextAussen {
    grid-area: 1 / 5 / span 1 / span 1;
    text-align: left;
    align-self: center;
    padding-left: 5px;
}

.Boot-Fuehrung-On {
    grid-area: 3 / 2 / span 1 / span 3;
}

.Boot-Fuehrung-Off {
    grid-area: 3 / 2 / span 1 / span 3;
    display: none
}



.Boot-FreitextInnenOben {
    grid-area: 2 / 2 / span 1 / span 2;
    font-weight: bold;
    text-align: left;
    align-self: flex-end;
    padding: 5px 0 0 5px;
}


.Boot-FreitextInnenUnten {
    grid-area: 5 / 4 / span 1 / span 1;
    font-weight: bold;
    text-align: right;
    align-self: flex-end;
    padding: 0 10px 5px 0;
}

 

.Boot-Logistik-On {
    grid-area: 5 / 2 / span 1 / span 3;
}

.Boot-Logistik-Off {
    grid-area: 5 / 2 / span 1 / span 3;
    display: none;
}



.Boot-Rufname {
    grid-area: 4 / 5 / span 2 / span 1;
    text-align: left;
    font-size: larger;
    align-self: flex-start;
    padding-left: 20px;
    font-weight: bold;
    text-overflow: clip;
}

.Boot-Von {
    grid-area: 7 / 2 / span 1 / span 1;
}

.Boot-Nach {
    grid-area: 7 / 4 / span 1 / span 1;
}

.Boot-Bewegungsart {
    grid-area: 7 / 3 / span 1 / span 1;
}

.Boot-Staerke {
    grid-area: 6 / 3 / span 1 / span 1;
    padding-top: 5px;
}


.Boot-Dtg {
    grid-area: 3 / 1 / span 2 / span 1;
    align-self: flex-start;
    text-align: right;
}

.Boot-Download {
    position: relative;
    top: -395px;
    left: 575px;
}
