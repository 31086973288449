.Ereignis-Innen {
    font-family: arial, sans-serif;
    font-size: large;
    width: 100%;
    height: 100%;;
    display: grid;
    grid-template: 3fr 145px 2fr 2fr / 2fr 30px 120px 30px 3fr;
}

.Ereignis-Container {
    border: dashed black 0px;
}

.Ereignis-Grundzeichen {
    grid-area: 2 / 2 / span 1 / span 3;
    align-self: center;
    display: grid;
    grid-template: 1fr/ 1fr;

}

.Ereignis-Grundzeichen img {
    grid-area: 1 / 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
    max-height: 160px;

}

.Ereignis-Symbol {
    grid-area: 2 / 2 / span 1 / span 3;
    align-self: center;
    display: grid;
    padding-bottom: 40px;
    grid-template: 1fr / 1fr;
}

.Ereignis-Symbol img {
    grid-area: 1/1;
    align-self: flex-start;
    justify-self: center;
    max-width: 130px;
    max-height: 120px;
}

.Ereignis-Symbol p {
    grid-area: 1/1;
    align-self: center;
    justify-self: center;
    max-width: 130px;
    max-height: 90px;
    font-weight: bold;
    text-align: center;
    font-size: 35px;
}

.Ereignis-Groessenordnung {
    grid-area: 1 / 2 / span 1 / span 1;
    align-self: end;
    display: none;
}

.Ereignis-Groessenordnung img {
    max-height: 1.4rem;
}

.Ereignis-Vstkverm {
    grid-area: 1 / 3 / span 1 / span 1;
    align-self: center;
    display: none;
}

.Ereignis-FreitextAussen {
    grid-area: 1 / 5 / span 1 / span 1;
    text-align: left;
    align-self: flex-end;
    padding-left: 5px;
}

.Ereignis-FreitextInnenOben {
    grid-area: 2 / 3 / span 1 / span 1;
    font-weight: bold;
    text-align: center;
    align-self: flex-start;
    padding-top: 5px;
}


.Ereignis-FreitextInnenUnten {
    grid-area: 2 / 3 / span 1 / span 1;
    font-weight: bold;
    text-align: left;
    align-self: flex-end;
    font-size: 40px;
}
 

.Ereignis-Logistik-On {
    grid-area: 4 / 2 / span 1 / span 3;
    padding: 2px;
}

.Ereignis-Logistik-Off {
    grid-area: 4 / 2 / span 1 / span 3;
    display: none;
}

.Ereignis-Rufname {
    grid-area: 2 / 5 / span 2 / span 1;
    text-align: left;
    font-size: larger;
    align-self: flex-start;
    padding-left: 5px;
    font-weight: bold;
    text-overflow: clip;
}

.Ereignis-Von {
    grid-area: 4 / 1 / span 1 / span 2;
    text-align: right;
    padding-left: 10px;
}

.Ereignis-Nach {
    grid-area: 4 / 4 / span 1 / span 2;
    text-align: left;
    padding-right: 10px;
}

.Ereignis-Bewegungsart {
    grid-area: 4 / 3 / span 1 / span 1;
    padding: 0 10px;
}

.Ereignis-Staerke {
    grid-area: 3 / 3 / span 1 / span 1;
    padding-top: 5px;
    align-self: flex-start;
    text-align: center;
}


.Ereignis-Dtg {
    grid-area: 2 / 1 / span 1 / span 1;
    text-align: right;
    align-self: flex-start;
}

.Ereignis-Download {
    position: relative;
    top: -395px;
    left: 575px;
}
